import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Article, Column3, Thumbnail, Title } from "../utils"

const RecentPost = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulPosts(
          sort: { order: DESC, fields: createdAt }
          filter: { recent: { eq: true } }
        ) {
          edges {
            node {
              id
              createdAt(formatString: "YYYY-MM-DD")
              slug
              tags {
                name
                id
              }
              title
              thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    `
  )
  const posts = data.allContentfulPosts.edges
  return (
    <Column3>
      {posts &&
        posts.map(({ node: post }) => {
          return (
            <Article key={post.title}>
              <Link to={`/blog/${post.slug}`}>
                <Thumbnail>
                  <GatsbyImage
                    image={getImage(post.thumbnail)}
                    alt={post.title}
                  />
                </Thumbnail>
                <Title>{post.title}</Title>
              </Link>
            </Article>
          )
        })}
    </Column3>
  )
}

export default RecentPost
