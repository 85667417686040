import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const TopBannerWrap = styled.section`
  position: relative;
  margin: 0;
  &::before {
    content: "";
    padding-top: 60%;
    display: block;
    @media ${({ theme }) => theme.break.tb} {
      padding-top: 40%;
    }
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
  }
`
const Textwrap = styled.section`
  display: none;
  position: absolute;
  top: -10%;
  right: clamp(1rem, calc((100% - 1200px) / 2), calc((100% - 1200px) / 2));
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[2]};
  background-color: ${({ theme }) => theme.colors.white};
  @media ${({ theme }) => theme.break.tb} {
    display: block;
  }
`
const HeroText = styled.h2`
  font-size: clamp(
    ${({ theme }) => theme.fontSize["sm"]},
    1.5vw,
    ${({ theme }) => theme.fontSize["2xl"]}
  );
  font-family: ${({ theme }) => theme.font.head};
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
  writing-mode: vertical-rl;
  letter-spacing: 2px;
  line-height: 1.8;
`

const TopBanner = () => {
  return (
    <TopBannerWrap>
      <StaticImage
        src={"../images/home-banner.jpg"}
        layout={"fullWidth"}
        formats={["auto", "webp"]}
        alt=""
      />
      <Textwrap>
        <HeroText>
          毎日でもつくりたいから、
          <br />
          毎日でも食べられるものを作る
        </HeroText>
      </Textwrap>
    </TopBannerWrap>
  )
}

export default TopBanner
