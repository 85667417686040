import React from "react"
import RecentPost from "../components/recentPost"
import RecentRecipe from "../components/recentRecipe"
import TopBanner from "../components/TopBanner"
import { Container, EnH2, Section } from "../utils"

const IndexPage = () => {
  return (
    <Container>
      <TopBanner />
      <Section>
        <EnH2 mt="6.5rem">Recipe</EnH2>
        <RecentRecipe />
      </Section>
      <Section>
        <EnH2 mt="6.5rem">Blog</EnH2>
        <RecentPost />
      </Section>
    </Container>
  )
}

export default IndexPage
