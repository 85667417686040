import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Article, Column3, Thumbnail, Title } from "../utils"

const Category = styled.p`
  position: absolute;
  top: -4%;
  left: 4%;
  padding: ${({ theme }) => theme.spacing[1]} 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  writing-mode: vertical-rl;
`

const RecentRecipe = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulRecipes: allContentfulRecipes(
          sort: { fields: title, order: DESC }
          filter: { recent: { eq: true } }
        ) {
          edges {
            node {
              id
              title
              slug
              thumbnail {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              category {
                name
                slug
              }
              createdAt(formatString: "YYYY-MM-DD")
            }
          }
        }
      }
    `
  )
  const recipes = data.allContentfulRecipes.edges
  return (
    <Column3>
      {recipes &&
        recipes.map(({ node: recipe }) => {
          return (
            <Article key={recipe.title}>
              <Link to={`recipe/${recipe.slug}`}>
                <Thumbnail>
                  <GatsbyImage
                    image={getImage(recipe.thumbnail)}
                    alt={recipe.title}
                  />
                </Thumbnail>
                <Title>{recipe.title}</Title>
                <Category>{recipe.category.name}</Category>
              </Link>
            </Article>
          )
        })}
    </Column3>
  )
}

export default RecentRecipe
